import { HorizontalScrollView } from "@clipboard-health/ui-components";
import { type ReactNode } from "react";

interface ShiftBottomSheetCardsWrapperProps {
  children: ReactNode;
}

export function ShiftBottomSheetCardsWrapper(props: ShiftBottomSheetCardsWrapperProps) {
  const { children } = props;

  return (
    <HorizontalScrollView
      stretch
      sx={{ px: 5, height: "max-content", "&:not(:empty)": { pt: 7, pb: 3 } }}
    >
      {children}
    </HorizontalScrollView>
  );
}
