import { Illustration, type IllustrationType } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { Box, CardContent, Stack, useMediaQuery } from "@mui/material";
import { type ReactNode } from "react";

import { ShiftBottomSheetInnerCard, type ShiftBottomSheetInnerCardIntent } from "./InnerCard";

export interface ShiftBottomSheetBookabilityCriteriaCardProps {
  pill?: ReactNode;
  illustrationType: IllustrationType;
  title: ReactNode;
  description: ReactNode;
  footer?: ReactNode;
  intent?: ShiftBottomSheetInnerCardIntent;
}

/**
 * This is a generic card that is used to display info about unmet bookability criteria
 * All of such cards display similar UI with some custom texts and illustration
 */
export function ShiftBottomSheetBookabilityCriteriaCard(
  props: ShiftBottomSheetBookabilityCriteriaCardProps
) {
  const { title, pill, description, illustrationType, footer, intent } = props;

  // todo: should we look into introducing theme level breakpoints for different
  // mobile widths/heights and have a better way to control this?
  const isSmallVerticalSpacing = useMediaQuery("(max-height: 700px)");
  const isSmallHorizontalSpacing = useMediaQuery("(max-width: 350px)");

  return (
    <ShiftBottomSheetInnerCard intent={intent}>
      <CardContent
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          py: isSmallVerticalSpacing ? 1 : undefined,
          px: isSmallHorizontalSpacing ? 2 : 6,
        }}
      >
        {isDefined(pill) && <Box sx={{ width: "100%", alignSelf: "flex-start" }}>{pill}</Box>}

        <Stack
          alignItems="center"
          justifyContent="center"
          spacing={isSmallVerticalSpacing ? 2 : 5}
          sx={{
            textAlign: "center",
            textWrap: "balance",
            width: "100%",
            flexGrow: 1,
            mb: isDefined(footer) ? "auto" : undefined,
          }}
        >
          <Illustration type={illustrationType} />

          <Text semibold variant="h5">
            {title}
          </Text>

          <Text
            color={(theme) => theme.palette.text.secondary}
            variant="body2"
            data-testid="bookability-sheet-description"
          >
            {description}
          </Text>
        </Stack>

        {isDefined(footer) && (
          <Box sx={{ width: "100%", mt: isSmallVerticalSpacing ? 2 : 5 }}>{footer}</Box>
        )}
      </CardContent>
    </ShiftBottomSheetInnerCard>
  );
}
