import { useShiftModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useShiftModalsDataContext";
import { isEmpty } from "lodash";

import { ShiftBottomSheetBlockingRequirementsCard } from "./Card";
import { getIndividualBlockingRequirements } from "./getIndividualBlockingRequirements";
import { ShiftBottomSheetGroupedBlockingRequirementsCard } from "./GroupedCard";
import { shouldGroupBlockingRequirementCards } from "./shouldGroupBlockingRequirementCards";

interface ShiftBottomSheetBlockingRequirementsContainerProps {
  shiftId: string;
  hasExternalBlockingCards: boolean;
  workplaceId: string;
  workplaceName: string;
}

export function ShiftBottomSheetBlockingRequirementsContainer(
  props: ShiftBottomSheetBlockingRequirementsContainerProps
) {
  const { shiftId, hasExternalBlockingCards, workplaceId, workplaceName } = props;

  const { blockingRequirements, resolvedShiftQualification } = useShiftModalsDataContext();

  if (shouldGroupBlockingRequirementCards(blockingRequirements)) {
    return (
      <ShiftBottomSheetGroupedBlockingRequirementsCard
        blockingRequirements={blockingRequirements}
        shiftId={shiftId}
        qualification={resolvedShiftQualification}
        workplaceId={workplaceId}
        workplaceName={workplaceName}
      />
    );
  }

  const individualBlockingRequirements = getIndividualBlockingRequirements(
    blockingRequirements,
    hasExternalBlockingCards
  );

  if (!isEmpty(individualBlockingRequirements)) {
    return (
      <>
        {individualBlockingRequirements.map((individualBlockingRequirement) => (
          <ShiftBottomSheetBlockingRequirementsCard
            key={individualBlockingRequirement.requirementId}
            blockingRequirement={individualBlockingRequirement}
            shiftId={shiftId}
            qualification={resolvedShiftQualification}
            workplaceId={workplaceId}
          />
        ))}
      </>
    );
  }

  return null;
}
