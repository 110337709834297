// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/max-dependencies */
import { isDefined } from "@clipboard-health/util-ts";
import { useClipboardScoreStatus } from "@src/appV2/ClipboardScore/useClipboardScoreStatus";
import { useDiscardLatenessBooktimeThreshold } from "@src/appV2/ClipboardScore/useDiscardLatenessBooktimeThreshold";
import { useCurrentDate } from "@src/appV2/lib/utils/useCurrentDate";
import {
  BookabilityDecision,
  type BookabilityStatusItem,
  BookabilityUnmetCriteria,
} from "@src/appV2/OpenShifts/ShiftAction";
import { hasUnmetBookabilityCriteria } from "@src/appV2/OpenShifts/ShiftAction/hasUnmetBookabilityCriteria";
import { minutesToMilliseconds, parseISO } from "date-fns";

import { ShiftType } from "../constants";
import { type ModalWorkplaceData } from "../types";
import { ShiftBottomSheetBlockingRequirementsContainer } from "./BlockingRequirements/Container";
import { ShiftBottomSheetCardsWrapper } from "./CardsWrapper";
import { ShiftBottomSheetHourRestrictionsCard } from "./FacilityHourRestrictionsCard";
import { ShiftBottomSheetMarkedAsInterestedCard } from "./MarkedAsInterestedCard";
import { ShiftBottomSheetNotInstantBookableCard } from "./NotInstantBookableCard";
import { ShiftBottomSheetPriorityShiftCard } from "./PriorityCard";
import { ShiftBottomSheetPriorityLegacyShiftCard } from "./PriorityLegacyCard";
import { ShiftBottomSheetPriorityPlusShiftCard } from "./PriorityPlusCard";
import { ShiftBottomSheetQuizCard } from "./QuizCard";
import { ShiftBottomSetupPaymentsCard } from "./SetupPaymentsCard";
import { ShiftFailedLoadingCard } from "./ShiftFailedLoadingCard";
import { ShiftNotBookableCard } from "./ShiftNotBookableCard";
import { ShiftBottomSignAgreementCard } from "./SignAgreementCard";
import { SkillAssessmentsRequiredCard } from "./SkillAssessmentsCard/SkillAssessmentsRequiredCard";
import { ShiftBottomSheetUrgentShiftCard } from "./UrgentShiftCard";

export enum ShiftBottomSheetBookabilityCard {
  FAILED_LOADING = "failed_loading",
  NOT_BOOKABLE = "not_bookable",
  MARKED_AS_INTERESTED = "marked_as_interested",
  NOT_INSTANT_BOOKABLE = "not_instant_bookable",
  AGREEMENT_TO_SIGN = "agreement_to_sign",
  PAYMENT_DISABLED = "payment_disabled",
  BLOCKING_REQUIREMENTS = "blocking_requirements",
  SKILLS_ASSESSMENT = "skills_assessment",
  QUIZ = "quiz",
  HOURS_RESTRICTION = "hours_restriction",
  URGENT = "urgent",
  PRIORITY_PLUS = "priority_plus",
  PRIORITY = "priority",
  PRIORITY_LEGACY = "priority_legacy",
}

interface ShiftBottomSheetBookabilityCardsContainerProps {
  shiftId: string;
  shiftType: ShiftType;
  workplace: ModalWorkplaceData;
  bookabilityStatus: BookabilityStatusItem;
  hasBlockingRequirements: boolean;
  hasMarkedInterest?: boolean;
  shiftFailedLoading: boolean;
  hasHoursRestrictionConflict: boolean;
  shiftStartIso?: string;
  cardsToSkip?: ShiftBottomSheetBookabilityCard[];
  children?: React.ReactNode;
}

// eslint-disable-next-line complexity
export function ShiftBottomSheetBookabilityCardsContainer(
  props: ShiftBottomSheetBookabilityCardsContainerProps
) {
  const {
    shiftId,
    shiftType,
    shiftStartIso,
    workplace,
    bookabilityStatus,
    hasBlockingRequirements,
    hasMarkedInterest,
    shiftFailedLoading,
    hasHoursRestrictionConflict,
    cardsToSkip = [],
    children,
  } = props;

  const {
    name: workplaceName,
    maxAllowedWorkConsecutiveHours,
    maxAllowedWorkHoursPerWeek,
    preventDoubleShifts,
  } = workplace.attributes ?? {};

  // Refresh this component every minute to get the latest priority status
  useCurrentDate(minutesToMilliseconds(1));

  const clipboardScoreStatus = useClipboardScoreStatus();
  const discardLatenessBooktimeThreshold = useDiscardLatenessBooktimeThreshold();

  const hasHourRestrictions =
    isDefined(maxAllowedWorkConsecutiveHours) || isDefined(maxAllowedWorkHoursPerWeek);

  const hasQuizToPass = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_QUIZ_FOR_WORKPLACE_RULES,
  ]);

  const hasSkillAssessmentToPass = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_ASSESSMENTS,
  ]);
  const skillAssessmentRequiredMetadata =
    "unmetCriteriaWithMetadata" in bookabilityStatus.attributes.bookability &&
    bookabilityStatus.attributes.bookability.unmetCriteriaWithMetadata.find(
      (unmetCriteriaWithMetadata) =>
        unmetCriteriaWithMetadata.unmetCriteria ===
        BookabilityUnmetCriteria.WORKER_HAS_NOT_PASSED_ASSESSMENTS
    )?.metadata;

  const hasAgreementToSign = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_UNSIGNED_AGREEMENT,
  ]);

  const hasPaymentsDisabled = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.WORKER_PAYMENTS_DISABLED,
  ]);

  const isInstantBookDisabled = hasUnmetBookabilityCriteria(bookabilityStatus, [
    BookabilityUnmetCriteria.SHIFT_INSTANT_BOOK_DISABLED,
  ]);

  const isBookable =
    bookabilityStatus?.attributes.bookability.decision === BookabilityDecision.ALLOWED;
  return (
    <ShiftBottomSheetCardsWrapper>
      {!cardsToSkip.includes(ShiftBottomSheetBookabilityCard.FAILED_LOADING) &&
        isBookable &&
        shiftFailedLoading && <ShiftFailedLoadingCard />}
      {!cardsToSkip.includes(ShiftBottomSheetBookabilityCard.NOT_BOOKABLE) &&
        !isBookable &&
        shiftFailedLoading && <ShiftNotBookableCard />}

      {!cardsToSkip.includes(ShiftBottomSheetBookabilityCard.AGREEMENT_TO_SIGN) &&
        hasAgreementToSign && <ShiftBottomSignAgreementCard shiftId={shiftId} />}
      {!cardsToSkip.includes(ShiftBottomSheetBookabilityCard.PAYMENT_DISABLED) &&
        hasPaymentsDisabled && <ShiftBottomSetupPaymentsCard shiftId={shiftId} />}
      {!cardsToSkip.includes(ShiftBottomSheetBookabilityCard.BLOCKING_REQUIREMENTS) &&
        hasBlockingRequirements && (
          <ShiftBottomSheetBlockingRequirementsContainer
            shiftId={shiftId}
            hasExternalBlockingCards={hasAgreementToSign || hasPaymentsDisabled}
            workplaceId={workplace.id}
            workplaceName={workplaceName}
          />
        )}
      {!cardsToSkip.includes(ShiftBottomSheetBookabilityCard.SKILLS_ASSESSMENT) &&
        hasSkillAssessmentToPass &&
        skillAssessmentRequiredMetadata && (
          <SkillAssessmentsRequiredCard metadata={skillAssessmentRequiredMetadata} />
        )}
      {!cardsToSkip.includes(ShiftBottomSheetBookabilityCard.QUIZ) && hasQuizToPass && (
        <ShiftBottomSheetQuizCard shiftId={shiftId} />
      )}
      {!cardsToSkip.includes(ShiftBottomSheetBookabilityCard.HOURS_RESTRICTION) &&
        hasHourRestrictions &&
        hasHoursRestrictionConflict && (
          <ShiftBottomSheetHourRestrictionsCard
            maxAllowedWorkConsecutiveHours={maxAllowedWorkConsecutiveHours}
            maxAllowedWorkHoursPerWeek={maxAllowedWorkHoursPerWeek}
            preventDoubleShifts={preventDoubleShifts}
          />
        )}
      {isInstantBookDisabled &&
        (hasMarkedInterest
          ? !cardsToSkip.includes(ShiftBottomSheetBookabilityCard.MARKED_AS_INTERESTED) && (
              <ShiftBottomSheetMarkedAsInterestedCard />
            )
          : !cardsToSkip.includes(ShiftBottomSheetBookabilityCard.NOT_INSTANT_BOOKABLE) && (
              <ShiftBottomSheetNotInstantBookableCard />
            ))}
      {!cardsToSkip.includes(ShiftBottomSheetBookabilityCard.URGENT) &&
        shiftType === ShiftType.URGENT &&
        isDefined(shiftStartIso) && (
          <ShiftBottomSheetUrgentShiftCard
            startDate={parseISO(shiftStartIso)}
            discardLatenessBooktimeThreshold={discardLatenessBooktimeThreshold}
          />
        )}
      {!cardsToSkip.includes(ShiftBottomSheetBookabilityCard.PRIORITY_PLUS) &&
        shiftType === ShiftType.PRIORITY_PLUS && <ShiftBottomSheetPriorityPlusShiftCard />}
      {!cardsToSkip.includes(ShiftBottomSheetBookabilityCard.PRIORITY) &&
        shiftType === ShiftType.PRIORITY && (
          <ShiftBottomSheetPriorityShiftCard
            isClipboardScore={clipboardScoreStatus.isWorkerClipboardScoreTargeted}
          />
        )}
      {!cardsToSkip.includes(ShiftBottomSheetBookabilityCard.PRIORITY_LEGACY) &&
        shiftType === ShiftType.PRIORITY_LEGACY && <ShiftBottomSheetPriorityLegacyShiftCard />}

      {children}
    </ShiftBottomSheetCardsWrapper>
  );
}
