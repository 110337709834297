import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { LoadingButton } from "../../components/LoadingButton";
import { useShiftModalsDataContext } from "../../ShiftDiscovery/useShiftModalsDataContext";
import { useBookShift } from "../Booking/useBookShift";

interface BookShiftButtonProps {
  shiftId: string;
  shiftOfferId?: string;
  workplaceId?: string;
  onBook: () => void;
  disabled?: boolean;
}

// TODO: Following features need to be ported: Rate negotiation, request non-IP shift, conflicting shift invites
export function BookShiftButton(props: BookShiftButtonProps) {
  const { onBook, shiftId, shiftOfferId, workplaceId, disabled } = props;

  const { navigateToModal } = useShiftModalsDataContext();

  const { attemptBookingShift, isBooking, isLoading } = useBookShift({
    shiftId,
    shiftOfferId,
    workplaceId,
    navigateToModal,
    onBook,
  });

  return (
    <LoadingButton
      size="large"
      variant="contained"
      isLoading={isLoading || isBooking}
      disabled={disabled}
      sx={{ minWidth: "30%" }}
      onClick={async () => {
        logEvent(APP_V2_USER_EVENTS.SHIFT_DETAILS_CTA_BOOK_SHIFT, { shiftId });

        await attemptBookingShift();
      }}
    >
      {disabled ? "Can't book" : "Book"}
    </LoadingButton>
  );
}
