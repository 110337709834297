import { WorkerRequirementStatus } from "@clipboard-health/contract-worker-app-bff";
import { type IllustrationType } from "@clipboard-health/ui-components";
import { isDefined } from "@clipboard-health/util-ts";
import { ACCOUNTS_HCF_DOCUMENT_BY_ID_AND_NAME_PATH } from "@src/appV2/Accounts/Documents/paths";
import { ACCOUNTS_PATH } from "@src/appV2/Accounts/paths";
import { RootPaths } from "@src/appV2/App/paths";
import { Button } from "@src/appV2/redesign/components/Button";
import { type WorkerRequirement } from "@src/appV2/redesign/Documents/api/types";
import { DocumentStatusPill } from "@src/appV2/redesign/Documents/components/StatusPill";
import { isRequirementInstantReview } from "@src/appV2/redesign/Documents/utils/isRequirementInstantReview";
import { SHIFT_DISCOVERY_SHIFT_HELLO_SIGN_CONTAINER_MODAL_PATH } from "@src/appV2/redesign/ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useShiftModalsDataContext";
import pluralize from "pluralize";
import { generatePath, useHistory } from "react-router-dom";

import { QuickSignPill } from "../../Bookability/Documents/InstantReview/QuickSignPill";
import { ShiftBottomSheetBookabilityCriteriaCard } from "../BookabilityCriteriaCard";

interface ShiftBottomSheetGroupedBlockingRequirementsCardProps {
  blockingRequirements?: WorkerRequirement[];
  shiftId: string;
  qualification?: string;
  workplaceId: string;
  workplaceName: string;
}

export function ShiftBottomSheetGroupedBlockingRequirementsCard(
  props: ShiftBottomSheetGroupedBlockingRequirementsCardProps
) {
  const { blockingRequirements, shiftId, qualification, workplaceId, workplaceName } = props;
  const { navigateToModal } = useShiftModalsDataContext();
  const history = useHistory();

  const visibleBlockingWorkerRequirements =
    blockingRequirements?.filter(({ visibleToHCP }) => visibleToHCP === true) ?? [];

  const areAllVisibleBlockedDocumentsInReview = visibleBlockingWorkerRequirements.every(
    (requirement) => requirement.status === WorkerRequirementStatus.IN_REVIEW
  );

  const areAllVisibleBlockedDocumentsInstantReview = visibleBlockingWorkerRequirements.every(
    (requirement) =>
      isRequirementInstantReview({
        workerRequirement: requirement,
      })
  );

  function getPill() {
    if (areAllVisibleBlockedDocumentsInReview) {
      return <DocumentStatusPill variant="in_review" />;
    }

    if (areAllVisibleBlockedDocumentsInstantReview) {
      return <QuickSignPill />;
    }

    return null;
  }

  function getIcon(): IllustrationType {
    if (areAllVisibleBlockedDocumentsInReview) {
      return "document-review";
    }

    if (areAllVisibleBlockedDocumentsInstantReview) {
      return "quiz-required";
    }

    return "document-upload";
  }

  function getTitleAndDescription() {
    if (areAllVisibleBlockedDocumentsInReview) {
      const numberOfDocumentsInReview = visibleBlockingWorkerRequirements.length;
      return {
        title: `You completed ${numberOfDocumentsInReview} ${pluralize(
          "document",
          numberOfDocumentsInReview
        )}`,
        description: `The ${numberOfDocumentsInReview} ${pluralize(
          "document",
          numberOfDocumentsInReview
        )} you've submitted are being reviewed by our team`,
      };
    }

    const numberOfDocuments =
      visibleBlockingWorkerRequirements.length -
      visibleBlockingWorkerRequirements.filter(
        (requirement) => requirement.status === WorkerRequirementStatus.IN_REVIEW
      ).length;

    return {
      title: `This workplace requires ${numberOfDocuments} ${pluralize(
        "document",
        numberOfDocuments
      )}`,
      description: `Please complete ${numberOfDocuments} more ${pluralize(
        "document",
        numberOfDocuments
      )} in order to book this shift.`,
    };
  }

  function onClick() {
    if (areAllVisibleBlockedDocumentsInstantReview) {
      navigateToModal(
        SHIFT_DISCOVERY_SHIFT_HELLO_SIGN_CONTAINER_MODAL_PATH,
        {
          requirementId: visibleBlockingWorkerRequirements[0].requirementId,
          shiftId,
        },
        true
      );
      return;
    }

    const path = generatePath(
      `${RootPaths.APP_V2_HOME}/${ACCOUNTS_PATH}/${ACCOUNTS_HCF_DOCUMENT_BY_ID_AND_NAME_PATH}`,
      {
        hcfId: workplaceId,
        hcfName: workplaceName,
      }
    );
    const queryParams = isDefined(qualification) ? `?qualification=${qualification}` : "";
    history.push(`${path}${queryParams}`, {
      returnUrl: location.pathname,
    });
  }

  const { title, description } = getTitleAndDescription();

  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      title={title}
      description={description}
      illustrationType={getIcon()}
      pill={getPill()}
      footer={
        <Button
          fullWidth
          variant={areAllVisibleBlockedDocumentsInReview ? "outlined" : "contained"}
          size="medium"
          onClick={() => {
            onClick();
          }}
        >
          {areAllVisibleBlockedDocumentsInstantReview ? "Complete" : "View Details"}
        </Button>
      }
    />
  );
}
