import { WorkerRequirementStatus } from "@clipboard-health/contract-worker-app-bff";
import { isNil } from "@clipboard-health/util-ts";
import { type WorkerRequirement } from "@src/appV2/redesign/Documents/api/types";
import { partition, sortBy } from "lodash";

export function getIndividualBlockingRequirements(
  workerRequirements: WorkerRequirement[] | undefined,
  hasExternalBlockingCards: boolean
) {
  if (isNil(workerRequirements)) {
    return [];
  }

  const [visibleBlockingRequirements, invisibleBlockingRequirements] = partition(
    workerRequirements,
    ({ visibleToHCP }) => visibleToHCP === true
  );

  if (visibleBlockingRequirements.length === 0) {
    return hasExternalBlockingCards
      ? []
      : sortBy(invisibleBlockingRequirements, ({ name }) => name ?? "");
  }

  const [inReview, notInReview] = partition(
    visibleBlockingRequirements,
    ({ status }) => status === WorkerRequirementStatus.IN_REVIEW
  );

  const sortedInReview = sortBy(inReview, ({ name }) => name ?? "");
  const sortedNotInReview = sortBy(notInReview, ({ name }) => name ?? "");

  return sortedNotInReview.length === 0 ? sortedInReview : sortedNotInReview;
}
