import { WorkerRequirementStatus } from "@clipboard-health/contract-worker-app-bff";
import { type IllustrationType } from "@clipboard-health/ui-components";
import { isDefined } from "@clipboard-health/util-ts";
import { generateDocumentDetailsPath } from "@src/appV2/Accounts/DocumentDetails/generateDocumentDetailsPath";
import { RequirementWorkflowType } from "@src/appV2/Accounts/Documents/resources/requirements/constants";
import { DocumentRequirementType } from "@src/appV2/Accounts/Documents/types";
import { openZendeskMessaging } from "@src/appV2/lib/ZendeskMessaging/openZendeskMessaging";
import { Button } from "@src/appV2/redesign/components/Button";
import { type WorkerRequirement } from "@src/appV2/redesign/Documents/api/types";
import {
  DocumentStatusPill,
  type DocumentStatusPillVariant,
} from "@src/appV2/redesign/Documents/components/StatusPill";
import { isRequirementInstantReview } from "@src/appV2/redesign/Documents/utils/isRequirementInstantReview";
import { SHIFT_DISCOVERY_SHIFT_HELLO_SIGN_CONTAINER_MODAL_PATH } from "@src/appV2/redesign/ShiftDiscovery/paths";
import { useShiftModalsDataContext } from "@src/appV2/redesign/ShiftDiscovery/useShiftModalsDataContext";
import { parseISO } from "date-fns";
import { useHistory } from "react-router-dom";

import { QuickSignPill } from "../../Bookability/Documents/InstantReview/QuickSignPill";
import { ShiftBottomSheetBookabilityCriteriaCard } from "../BookabilityCriteriaCard";

interface ShiftBottomSheetBlockingRequirementsCardProps {
  blockingRequirement: WorkerRequirement;
  shiftId: string;
  qualification?: string;
  workplaceId: string;
}

export const WORKER_REQUIREMENT_STATUS_TO_PILL_MAP: Record<
  WorkerRequirementStatus,
  DocumentStatusPillVariant | undefined
> = {
  [WorkerRequirementStatus.REJECTED]: "rejected",
  [WorkerRequirementStatus.EXPIRED]: "expired",
  [WorkerRequirementStatus.EXPIRING]: "expiring_soon",
  [WorkerRequirementStatus.IN_REVIEW]: "in_review",
  [WorkerRequirementStatus.APPROVED]: undefined,
  [WorkerRequirementStatus.MISSING]: undefined,
};

export const WORKER_REQUIREMENT_STATUS_TO_DOCUMENT_DETAILS_PATH_MAP: Record<
  WorkerRequirementStatus,
  DocumentRequirementType
> = {
  [WorkerRequirementStatus.MISSING]: DocumentRequirementType.MISSING,
  [WorkerRequirementStatus.REJECTED]: DocumentRequirementType.REJECTED,
  [WorkerRequirementStatus.EXPIRED]: DocumentRequirementType.EXPIRED,
  [WorkerRequirementStatus.EXPIRING]: DocumentRequirementType.COMPLETED,
  [WorkerRequirementStatus.IN_REVIEW]: DocumentRequirementType.PENDING,
  [WorkerRequirementStatus.APPROVED]: DocumentRequirementType.COMPLETED,
};

export function ShiftBottomSheetBlockingRequirementsCard(
  props: ShiftBottomSheetBlockingRequirementsCardProps
) {
  const { blockingRequirement, shiftId, qualification, workplaceId } = props;

  const history = useHistory();
  const { navigateToModal } = useShiftModalsDataContext();

  const isInstantReview = isRequirementInstantReview({
    workerRequirement: blockingRequirement,
  });

  function getPill() {
    if (!blockingRequirement.visibleToHCP) {
      return <DocumentStatusPill variant="in_review" size="medium" />;
    }

    if (isInstantReview && blockingRequirement.status !== WorkerRequirementStatus.IN_REVIEW) {
      return <QuickSignPill />;
    }

    const variant = WORKER_REQUIREMENT_STATUS_TO_PILL_MAP[blockingRequirement.status];

    if (isDefined(variant)) {
      return (
        <DocumentStatusPill
          variant={variant}
          size="medium"
          expiry={
            isDefined(blockingRequirement.expiry) ? parseISO(blockingRequirement.expiry) : undefined
          }
        />
      );
    }

    return undefined;
  }

  function getActionText() {
    if (blockingRequirement.requirementWorkflowType === RequirementWorkflowType.DOCUMENT_UPLOAD) {
      return "Upload";
    }

    if (
      blockingRequirement.requirementWorkflowType === RequirementWorkflowType.SIGN_WITH_HELLOSIGN
    ) {
      return "Sign";
    }

    return "Complete";
  }

  function getButtonText() {
    if (!blockingRequirement.visibleToHCP) {
      return "Contact Support";
    }

    if (blockingRequirement.status === WorkerRequirementStatus.IN_REVIEW) {
      return "View Details";
    }

    return getActionText();
  }

  function getIcon(): IllustrationType {
    if (
      blockingRequirement.status === WorkerRequirementStatus.IN_REVIEW ||
      !blockingRequirement.visibleToHCP
    ) {
      return "document-review";
    }

    if (isInstantReview) {
      return "quiz-required";
    }

    return "document-upload";
  }

  function getDescription() {
    if (!blockingRequirement.visibleToHCP) {
      return "We're processing this document on your behalf.";
    }

    if (isInstantReview) {
      return "The document will be reviewed instantly.";
    }

    if (blockingRequirement.status === WorkerRequirementStatus.IN_REVIEW) {
      return "The document is currently being reviewed by our team.";
    }

    if (blockingRequirement.status === WorkerRequirementStatus.EXPIRING) {
      return "This document will expire before the shift date. Please complete it again.";
    }

    if (blockingRequirement.status === WorkerRequirementStatus.REJECTED) {
      return "Your document was rejected. Please review and resubmit.";
    }

    if (blockingRequirement.status === WorkerRequirementStatus.EXPIRED) {
      return "Your document has expired. Please review and resubmit.";
    }

    return `Please ${getActionText().toLowerCase()} this document before booking this shift.`;
  }

  function getTitle() {
    return blockingRequirement.name;
  }

  const onClick = async () => {
    if (!blockingRequirement.visibleToHCP) {
      await openZendeskMessaging();
      return;
    }

    if (isInstantReview) {
      navigateToModal(
        SHIFT_DISCOVERY_SHIFT_HELLO_SIGN_CONTAINER_MODAL_PATH,
        {
          requirementId: blockingRequirement.requirementId,
          shiftId,
        },
        true
      );
      return;
    }

    const documentDetailsPath = generateDocumentDetailsPath({
      hcfId: workplaceId,
      requirement: blockingRequirement.requirementId,
      requirementStatus:
        WORKER_REQUIREMENT_STATUS_TO_DOCUMENT_DETAILS_PATH_MAP[blockingRequirement.status],
      qualification,
    });

    history.push(documentDetailsPath);
  };

  return (
    <ShiftBottomSheetBookabilityCriteriaCard
      title={getTitle()}
      description={getDescription()}
      illustrationType={getIcon()}
      pill={getPill()}
      footer={
        <Button
          fullWidth
          variant={
            blockingRequirement.status === WorkerRequirementStatus.IN_REVIEW
              ? "outlined"
              : "contained"
          }
          size="medium"
          onClick={onClick}
        >
          {getButtonText()}
        </Button>
      }
    />
  );
}
