import { ListItem, ListItemIcon, ListItemText } from "@clipboard-health/ui-components";
import { Text } from "@clipboard-health/ui-react";
import { parseISO } from "date-fns";

import { TimeRangeLabel } from "../../DateTime/TimeRangeLabel";
import { formatHoursDurationCompact } from "../../utils/formatHoursDurationCompact";
import { getTimeSlotLabel } from "../getTimeSlotLabel";
import { type TimeSlot } from "../Open/types";
import { iconTypeByTimeSlot } from "../TimeSlotIcon";

interface ShiftBottomSheetDurationListItemProps {
  shiftTimeSlot: TimeSlot;
  shiftStartIso: string;
  shiftEndIso: string;
  shiftDurationInHours: number;
  workplaceTimeZone?: string;
}

export function ShiftBottomSheetDurationListItem(props: ShiftBottomSheetDurationListItemProps) {
  const { shiftTimeSlot, shiftStartIso, shiftEndIso, shiftDurationInHours, workplaceTimeZone } =
    props;

  return (
    <ListItem divider>
      <ListItemIcon iconType={iconTypeByTimeSlot[shiftTimeSlot]} />
      <ListItemText
        primary={
          <TimeRangeLabel
            semibold
            variant="h6"
            dateRange={{
              startDate: parseISO(shiftStartIso),
              endDate: parseISO(shiftEndIso),
            }}
            timezone={workplaceTimeZone}
          />
        }
        secondary={
          // Label like "8h 30m day shift"
          <Text variant="body2" sx={{ marginTop: 2 }}>
            {formatHoursDurationCompact(shiftDurationInHours)}{" "}
            {getTimeSlotLabel(shiftTimeSlot).toLowerCase()} shift
          </Text>
        }
      />
    </ListItem>
  );
}
