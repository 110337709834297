import { WorkerRequirementStatus } from "@clipboard-health/contract-worker-app-bff";
import { isNil } from "@clipboard-health/util-ts";
import { type WorkerRequirement } from "@src/appV2/redesign/Documents/api/types";
import { partition } from "lodash";

const NUMBER_BLOCKING_REQUIREMENTS_TO_GROUP_CARDS = 3;

export function shouldGroupBlockingRequirementCards(blockingRequirements?: WorkerRequirement[]) {
  if (isNil(blockingRequirements)) {
    return false;
  }

  const visibleBlockingRequirements = blockingRequirements.filter(
    ({ visibleToHCP }) => visibleToHCP === true
  );

  const [inReview, notInReview] = partition(
    visibleBlockingRequirements,
    ({ status }) => status === WorkerRequirementStatus.IN_REVIEW
  );

  return (
    (notInReview.length === 0 ? inReview.length : notInReview.length) >=
    NUMBER_BLOCKING_REQUIREMENTS_TO_GROUP_CARDS
  );
}
